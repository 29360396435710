<template>    
  <div class="background_basic">  
    <v-card class="ma-3">      
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-hammer-wrench</v-icon>장비 관리 [Ap]
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>
        <v-btn color="blue" text @click="ExcelExpert('장비 관리 [Ap]')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>

      
      <v-card flat class="d-flex">  
        <v-tabs v-model="model">
            <v-tab center-active class="ml-4 font-weight-bold" @click="goApPage()"> Ap 관리 </v-tab>
            <v-tab center-active class="font-weight-bold" @click="goTagPage()"> Tag 관리 </v-tab>
        </v-tabs>
      </v-card>
      <v-divider></v-divider>

      
      <v-card flat class="d-flex ml-3 mr-3">                
        <v-card flat class="ml-3 mb-1">             
          <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
        </v-card>                                 
      
        <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
          <v-combobox v-model="cNowCheck" class="mb-n5" label="배정여부" :items="check"></v-combobox>   
        </v-card>   
      
        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-3 mb-n5"
          label="검색어"
          placeholder="맥주소 입력"
          persistent-placeholder
          style="max-width:150px;"
          Regular
          v-on:keyup.enter="Search()"
        ></v-text-field>        
        <v-btn text class="ml-n1 mt-3 text-subtitle-1" @click="Search()">
          <v-icon>search</v-icon>검색
        </v-btn>
      </v-card>      
      <v-divider></v-divider>
      <v-data-table 
        :items-per-page="-1" 
        fixed-header 
        height="calc(100vh - 305px)" 
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick"
        >
        <template v-slot:item.action="{ item }">
          <!-- <v-icon big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">edit</v-icon> -->
          <v-icon big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD">delete</v-icon>
        </template>
        <!-- <template v-slot:item.sAssign="{ item }">
          <v-chip :color="getColor1(item.sAssign)" dark>{{item.sAssign}}</v-chip>
        </template>       
        <template v-slot:item.sUse="{ item }">
          <v-chip :color="getColor2(item.sUse)" dark>{{item.sUse}}</v-chip>
        </template> -->
        <template v-slot:item.cIpInfo="{ item }">
          <v-chip color="black" @click="editItem(item)" dark>설정변경</v-chip>
        </template>
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
    </v-card>    
    
    
    <!-- 수정 다이올로그, 필수항목 기입안했을시 알람표시, 미기입시 항목초기화 버튼생성 -->  
    <v-dialog v-model="dialog" max-width="600px">
      <v-card ref="form">
        <v-card-title >
          <span class="font-weight-bold">BLE AP 속성 설정</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.cApId" label="맥주소" :disabled="isEditMode"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.cServerIp" label="서버IP"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.cIp" label="AP고정IP"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.cPort" label="포트"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>                
          <v-spacer></v-spacer>
          <v-btn color="blue darken-2" text @click="save" :disabled="loading" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">save</v-icon>저장
          </v-btn>
          <v-btn color="grey darken-2" text @click="close" :disabled="loading" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">cancel</v-icon>취소
          </v-btn>
        </v-card-actions>              
        <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>
      </v-card>
    </v-dialog>

    
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />      
  </div>
</template>

<script>
import MsgBox from "../components/MsgBox.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import IronDate from '../components/iron28Calendar/src/IronDate.vue'

export default {
  components: {
    MsgBox,
    IronDate,    
  },
  data: () => ({
    sNowSelectDate :  Util.sFormatDate(Util.sMonthSel()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Util.sMonthSel()),
    sEndDate :Util.sFormatDate(Date()),
    cNowSearchKeyWord : "",
    cNowCheck: "",
    check: ["매핑중", "매핑해제"],
    cType: ["BLE AP", "WIFI AP"],
    cInOut: ["IN", "OUT", "사용안함"],
    sUse: ["사용", "사용안함"],
    loading : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,
    model : 0,
  
    headers: [
      { class: "font-weight-bold subtitle-2", text: "번호", value: "id", align: "center"},
      { class: "font-weight-bold subtitle-2", text: "맥주소", value: "cApId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "장비타입", value: "cType", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "배정여부", value: "sAssign", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "등록일", value: "cUpdateDateTime", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "사용여부", value: "sUse", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "구역정보설정", value: "cIpInfo", align: "center",  sortable: false},
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false},
    ],
    Records: [],
    editedIndex: -1,
    // userId(스토어), cDel(golang), cEtc(golang)만 제외하고 테이블의 항목을 설정함 
    editedItem: { 
      cApId: "",
      cMapTreeId: "",
      sAssign: "",
      cIp: "",
      cPort: "",
      x: "0",
      y: "0",
      cState: "",
      cType: "",
      cPosition: "",
      cInOut: "",
      cMac: "",
      cServerIp: "",
      cUse: "",
      sUse: "",
      cDel: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    deletedItem: {
      cApId: "",
      cMapTreeId: "",
      sAssign: "",
      cIp: "",
      cPort: "",
      x: "0",
      y: "0",
      cState: "",
      cType: "",
      cPosition: "",
      cInOut: "",
      cMac: "",
      cServerIp: "",
      cUse: "",
      sUse: "",
      cDel: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    defaultItem: {
      cApId: "",
      cMapTreeId: "",
      sAssign: "",
      cIp: "",
      cPort: "",
      x: "0",
      y: "0",
      cState: "",
      cType: "",
      cPosition: "",
      cInOut: "",
      cMac: "",
      cServerIp: "",
      cUse: "",
      sUse: "",
      cDel: "",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "신규 등록" : "항목 수정";
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {    
    initialize() {
      this.cNowSearchKeyWord = "";
      this.cNowCheck= "";

      this.sNowSelectDate = Util.sFormatDate(Util.sMonthSel()) + " ~ " +  Util.sFormatDate(Date());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Util.sMonthSel());
      this.sEndDate = Util.sFormatDate(Date());

      alert(this.sStartDate);

      this.Search();      
    },
   
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },
    
    getColor1(a) {
    if (a === "매핑중") return "#70bbfe"
    else return "#B9B9B9"
    },

    
    getColor2(a) {
      if (a === "사용") return "#70bbfe";
      else return "#B9B9B9";
    },

    
    ClearDialog() {      
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;                      
    },

    
    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       this.editItem(items.item);  
    },

    
    Search() {
      this.Records = [];
      var posData = {
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",
        cNowCheck: this.cNowCheck,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
      };
      axios
        .post(BasicInfo.UIL_API + "GetApInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;
            for (var i = 0; i < this.Records.length; i++) {
              this.Records[i].id = i+1;
              if (this.Records[i].cMapTreeId == "NONE" || "") {
                this.Records[i].sAssign = "매핑해제";
              } else {
                this.Records[i].sAssign = "매핑중";
              }
            switch (this.Records[i].cUse) {
              case "Y": 
                this.Records[i].sUse = "사용";
                break;
              case "N": 
                this.Records[i].sUse = "사용안함";
                break;
            }
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    // 신규로 장비등록함 (중복 확인을 위해 NewId와 Editid로 구분하여 axios로 보냄)
    // 신규항목이나 수정항목을 DB에 저장하고, Web화면의 records에 넣어줌 
    // DB에서는 변경항목 메인키와 업데이트 시간을 받아와서 수정항목에 넣어줌 
    save() {
      if (this.editedItem.cApId == "" ) {
        EventBus.$emit("onShowMsg",true, "맥주소는 필수입력 항목입니다.");
        return;
      }
      if (this.editedItem.cType == "" ) {
        EventBus.$emit("onShowMsg",true, "장비타입은 필수입력 항목입니다.");
        return;
      }
      if (this.editedItem.cInOut == "" ) {
        EventBus.$emit("onShowMsg",true, "InOut 정보는 필수입력 항목입니다.");
        return;
      }
      if (this.editedItem.sState == "" ) {
        EventBus.$emit("onShowMsg",true, "사용여부는 필수입력 항목입니다.");
        return;
      }
      this.editedItem.cUse = Util.sBoolToStr(this.editedItem.bUse); 
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,      
        cApId: this.editedItem.cApId,
        cNewApId: "",
        cEditApId: "",
        cMapTreeId: this.editedItem.cMapTreeId,
        cIp: this.editedItem.cIp,
        cPort: this.editedItem.cPort,
        x: parseFloat(this.editedItem.x),
        y: parseFloat(this.editedItem.y),
        cState: this.editedItem.cState,
        cType: this.editedItem.cType,
        cPosition: this.editedItem.cPosition,
        cInOut: this.editedItem.cInOut,
        cMac: this.editedItem.cMac,
        cNewMac: "",
        cEditMac: "",
        sUse: this.editedItem.sUse,
        cUse: this.editedItem.cUse,
        cServerIp: this.editedItem.cServerIp,
        cDel: this.editedItem.cDel,
        }

      if (this.isEditMode) {
        posData.cEditApId = this.editedItem.cApId;
      } else {
        posData.cNewApId = this.editedItem.cApId;
      }
      
      // if (this.isEditMode) {
      //   posData.cEditMac = this.editedItem.cMac;
      // } else {
      //   posData.cNewMac = this.editedItem.cMac;
      // }

      if (this.editedItem.sUse == "사용") {
        posData.cUse = "Y";
      } else if (this.editedItem.sUse == "사용안함") {
        posData.cUse = "N";
      } 

      console.log(posData);
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetApInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            if (this.isEditMode) {
              this.editedItem.cApId = res.data.Result[0].cApId;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);
            } else {
              this.editedItem.cApId = res.data.Result[0].cApId;
              this.editedItem.cCreateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.sAssign = "매핑해제"
              this.editedItem.id = this.Records.length +1;     // 자동카운트시
              this.Records.push(this.editedItem);
            }
            this.dialog = false;
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    
    // 신규등록/수정 다이올로그 취소시 닫음 (0.3초가 지나면 디폴트값으로 넣어줌)
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    // 수정 다이올로그 생성 (테이블상의 연필모양 클릭시, isEditMode는 수정못함)
    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);       
      this.dialog = true;
    },

    // 삭제알람 다이올로그 띄우기 (클릭시 DB에 등록 진행: onMsgBoxFunc와 연동)
    deleteItem(item) {
      this.iDelIndex = this.Records.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.deletedItem.cApId +
          "]"
      );
    },

    // (deleteItem()와 연동) DB에서 삭제하기 (del='N'으로 바꿈), web 화면상에 iDelIndex로 부터 첫번째 행을 제거함
    // onMsgBox 값 (취소시:false, 저장시:true)
    onMsgBoxFunc: function(result) {
      if (result === true) {
        //삭제
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          cApId: this.deletedItem.cApId,
        };
        axios
          .post(BasicInfo.UIL_API + "DelApInfo", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {
              this.Records.splice(this.iDelIndex, 1); 
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },
    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },
  }
};
</script>

